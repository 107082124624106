.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbar a, footer a {
  text-decoration: none;
  color: #495E57;
}

main h2 {
  color: #EDEFEE;
  font-family: "Markazi Text";
  font-size: 24pt;
}

.comments {
  min-height: 200px;
  width: 500px;
  margin-bottom: 10px;
  font-family: Karla;
}

.seating {
  color: #EDEFEE;
  font-family: "Markazi Text";
  font-size: 18pt;
  justify-content: center;
}

/** HERO SECTION */

.hero-section h1 {
  color: #F4CE14;
  font-family: "Markazi Text";
  font-size: 2.5rem;
  margin-top: 0;
}

.hero-section h2 {
  font-family: "Markazi Text";
  font-size: 1.5em;
  font-weight: normal;
  margin-top: -1em;

}

.hero-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1em;
  margin: 0 auto;
  color:  #EDEFEE;
  max-width: 1024px;
  font-weight: bold;

}

.hero-desc-section {
  padding: 1em 0;

}

.hero-image {
  height: 270px;
  width: 100%;
  border-radius: 1.5em;
  padding: 1em 0.2em;
  flex: 1;
}



.hero-reserve-table-button {
  height: 2.5em;
  width: 10em;
  border-radius: 0.5em;
  font-weight: bold;
  font-size: 1em;
  background-color: #F4CE14;
  border: none;
  color: #495E57;
}

.hero-description {
  max-width: 300px;
  justify-items: center;
}

/** END */



.home-icon {
  height: 2.5em;
  width: 100%;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1em;
  font-family: Karla;
  font-weight: bold;
  color: #495E57;
  text-decoration: none;
  margin: 0 auto;
  max-width: 1024px;
  padding: 0em 1em;
  padding-bottom: 1em;
  column-gap: 2em;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  font-family: Karla;
  font-weight: bold;
  color: #495E57;
  text-decoration: none;
  margin: 0 auto;
  max-width: 900px;
  column-gap: 2em;
  padding: 0.3em 1em;
}

footer {
  padding-top: 1em;
  padding-bottom: 1.5em;
}


footer a:hover {
  color:#d5815a;
}

footer li {
  padding-bottom: 0.3em;
}

footer p  {
  margin-bottom: 0.3em;
  margin-top: 0;
}




/** SPECIAL CARDS */

.special-card-img {
  height: 15em;
  width: 100%;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.special-card-title, .specials-heading, .testimonials-heading {
  display: flex;
  justify-content: space-between;
}

.specials-cards-container, .testimonials-cards-container {
  display: flex;

  flex-wrap: wrap;
  /* justify-content: center;
  gap: 1em;
  margin: 0 auto; */
  gap: 1em;
  max-width: 1024px;
}

.specials, .testimonials {
  margin: 2em auto;
  justify-content: center;
  max-width: 1024px;

}

.specials-card {
  background-color: #EDEFEE;
  border-radius: 0.5em;
  flex: 1;
  min-width: 250px;
}

.special-card-title, .specials-card-desc {
  padding: 0 0.7em;
  font-family: Karla;
}

.specials-card-desc {
  color:#495E57;

}
.special-card-title span:last-child {
  color: #EE9972;
}

.specials-menu-bttn {
  height: 1.7em;
  width: 7em;
  border-radius: 0.5em;
  font-weight: bold;
  font-size: 1em;
  background-color: #F4CE14;
  margin-bottom: 1em;
  border: none;
  color: #495E57;
}

.specials-heading span, .testimonials-heading {
  font-size: 1.5rem;
  font-family: "Markazi Text";
}

.specials {
  padding: 0 1em;
}




/** END */

/** ABOUT SECTION */

.about-section-container {
  background-color: #EE9972;

}

.about-images-section {
  display: grid;
  grid-template-columns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr";
  grid-template-rows: "1fr 1fr 1fr 1fr 1fr 1fr 1fr";
  padding: 1em 0;

}

.about-image-2 {
  grid-row: 1 / 5;
  grid-column: 1 / 5;
  border: 0.8em solid #FBDABB;
  width: 93%;
  height: 13em;
  border-radius: 0.5em;

}

.about-section {
  max-width: 800px;
}
.about-image-1 {
  grid-row: 3 / 7;
  grid-column: 3 / 7;
  border: 0.8em solid #495E57;
  width: 85%;
  height: 13em;;
  z-index: 1;
  border-radius: 0.5em;

}

.nav-item:hover, button:hover {
  color:#d5815a
}

button {
  cursor: pointer
}

/** END */



/**  BOOKING FORM SECTION */

.booking-form {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  align-items: left;
  margin: 0 auto;
  max-width: 400px;
  font-family: Karla;
  color:#EDEFEE;
}
.booking-form label {
  font-size: 1.1em;
  color:#EDEFEE;
  font-weight: bold;
}

.booking-form h1 {
  color:#F4CE14;
  margin-bottom: -0.1em;
  font-size: 2em;
  align-self: center;
  font-weight: bold;
}

.booking-form div {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  align-items: left;
}

.booking-form input, .booking-form select {
  border-radius: 0.5em;
  height: 2em;
  width: 100%;
}

.booking-form textarea {
  border-radius: 0.5em;
  height: 6em;
  width: 100%;
}

.booking-page {
  background-color: #495E57;
  padding: 2em 1em;
}

.booking-form-submit {
  height: 2em;
  border-radius: 0.5em;
  background-color: #F4CE14;
  font-family: Karla;
  font-size: 1.1em;
  font-weight: bold;
  border: none;
  color:#495E57;
}


/** END */


/**  CONFIRMED BOOKING SECTION */

.confirmed-booking-page {
  background-color: #495E57;
  color: white;
  padding: 1.2em 0.6em;
  height: 28em;
}

.confirmed-booking-header {
  background-image: url("./images/fish.jpg");
  background-position: center;
  background-size: 100% 40em;
  min-height: 180px;
  max-width: 600px;
  /** How to center a text inside a container */
  display: flex;
  align-items: center;
  justify-content: center;
    /** How to center a text inside a container */
  margin: 0 auto;
  border-radius: 0.5em;
}

.confirmed-booking-header > p {
  color:#EDEFEE;
  font-weight: bolder;
  font-size: 2.3em;
  font-family: "Markazi Text";
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.confirmed-booking-details {
  max-width: 500px;
  margin: 0 auto;
  font-family: Karla;

}

.confirmed-booking-details > p:first-child {
  color:#F4CE14;
  font-weight: bold;
}

/** END */

/**  TESTIMONIALS SECTION */

.testimonials-card {
  background-color: #EDEFEE;
  border-radius: 0.5em;
  flex: 1;
  text-align: center;

  padding: 0 0.7em;
  font-family: Karla;
  color: #495E57;
  min-width: 200px;
}

.testimonials-card-review {
  font-weight: bold;
  margin-top: -0.3em;
}

.testimonials-card img {
  border-radius: 100%;
  height: 5.5em;
  width: 5.5em;
}

.testimonials-container {
  background-color: #EE9972;
  padding: 0.3em 1em;
}


.menu-container {
  background-color: #e4c113;
  justify-items: center;
  display: grid;
  margin: 0 auto;
  justify-content: center;
}

.menu {
  max-width: 800px;
  display: flex;
  margin: 0 auto;
  padding-bottom: 2em;
  padding-right: 1em;
  padding-left: 1em;
}

.testimonials-heading {
  color:#495E57;
}




/** END */

.home-icon-box {
  justify-content: center;
  text-align: center;
  padding-top: 0.4em;
  padding-bottom: 0.3em;
  margin: 0 auto;
}

.footer-home-icon {
  padding-top: 0.5em;
}
